<template>
  <div class="member-container">
<van-nav-bar title="個人資料修改" right-text="關閉" @click-right="$router.push('/')" />

      <van-tabs :lazy-render="true" v-model:active="active" v-if="form.level > 0">
        <van-tab title="基本資料">
        </van-tab>
        <van-tab title="更多連結" v-if="form.level > 0">
        </van-tab>
      </van-tabs>

<van-form @submit="onSubmit">
  <van-cell-group inset v-show="active === 0">
    <van-field
      v-model="form.real_name"
      label="姓名"
      name="pattern"
      placeholder="請輸入您的姓名"
     :rules="[{ required: true, message: '姓名為必填' }]"
    />
    <van-field
      v-model="form.company"
      label="公司名稱"
      name=""
      placeholder="請輸入您的公司名稱"
     :rules="[{ required: true, message: '公司名稱必填' }]"
    />
    <van-field
      v-model="form.title"
      label="職稱"
      name=""
      placeholder="請輸入您的職稱"
    />
    <van-field
      v-model="form.phone"
      label="手機"
      name=""
      type="tel"
      placeholder="Ex. 0900000001 不要有空格"
     :rules="[{ required: true, message: '手機號必填' },{ pattern: /\d{10}/ , message: '手機號格式錯誤' }]"
    />
    <van-field
      v-model="form.tel"
      label="市話"
      name=""
      type="tel"
      placeholder="請輸入您的市話"
      :rules="[{ validator: validatorTel, message: '市話格式不正確,Ex. 02xxxx or 02-xxxx' }]"      
    />
    <van-field
      v-model="form.email"
      label="Email"
      name=""
      placeholder="請輸入您的Email"
     :rules="[{ required: true, message: 'Email必填' },{ pattern: /^([\w\.\-]){1,64}\@([\w\.\-]){1,64}/ , message: 'Email格式錯誤' }]"
    />
    <van-field
      v-model="form.address"
      label="住址"
      name=""
      placeholder="請輸入您的地址"
    />
    <van-field
      v-model="form.url"
      label="網址"
      name=""
      placeholder="請輸入您的網址"
      :rules="[{ validator: validatorUrl, message: '網址格式不正確,Ex. http://' }]"      
    />
    <van-field
      v-model="form.line"
      label="Line"
      name=""
      placeholder="請輸入您的Line ID"
    />
    <van-field
      v-model="form.facebook"
      label="Facebook"
      name=""
      placeholder="請輸入您的臉書連結"
    />
    <van-field
      v-model="form.ig"
      label="IG"
      name=""
      placeholder="請輸入您的IG ID"
    />
    <van-field
      v-model="form.youtube"
      label="YouTube"
      name=""
      placeholder="請輸入您的Youtube連結"
    />
    <van-field
      v-model="form.mark"
      rows="3"
      autosize
      label="簡介"
      type="textarea"
      maxlength="100"
      placeholder="請輸入簡介"
      show-word-limit
    >
    </van-field>    
    <van-uploader
      :after-read="afterRead"
      :max-count="1"
      name="averter"
      >
    <div class="upload-main">
          <img class="upload-img" :src="form.avatar" alt="">
          <p>上傳圖片</p>
    </div>    
    </van-uploader>
  </van-cell-group>
  <van-cell-group inset v-show="active === 1" v-for="(v,idx) in form.addon" :key="idx">
    <div class="act-btn">
      <van-button type="primary" icon="arrow-up" plain hairline @click="onMoveBtn(0,idx)">上移</van-button>
      <van-button type="primary" icon="arrow-down" plain hairline @click="onMoveBtn(1,idx)">下移</van-button>
      <van-button type="danger" icon="delete-o" plain hairline @click="onDelBtn(idx)">刪除</van-button>
    </div>
    <!-- <div class="field">
      <div class="label">圖示</div>
      <div class="content"><i class="iconfont icon-home"></i><span style="padding: 0 10px" @click="showChangeIcon=true">變更</span></div>
    </div> -->
    <van-field
      label="名稱"
      name=""
      v-model="form.addon[idx].name"
      placeholder="按鈕名稱"
    />
    <van-field
      label="連結"
      name=""
      v-model="form.addon[idx].link"
      placeholder="按鈕連結"
    />
  </van-cell-group>
  <div class="add-btn" v-show="active === 1" @click="onAddBtn">
    <van-button type="success" plain hairline>新增按鈕</van-button>
  </div>
  <div style="margin: 16px;">
    <van-button round block type="primary" native-type="submit">
      送出
    </van-button>
  </div>
</van-form>
<van-popup v-model:show="showChangeIcon">
<div class="icon-list">
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  <i class="iconfont icon-home" style="font-size: 40px"></i>
  </div>
  <div class="icon-item">
  </div>
</div>
</van-popup>
<Footer/>
    <van-overlay :show="crop.show" @click="crop.show = false" />
    <div class="cropper-section" v-if="crop.show">
      <div class="crop-area">
            <cropper
                class="cropper"
                ref="myCrop"
                :src="crop.img"
                :stencil-props="{
                    aspectRatio: 1/1
                }"
                :auto-zoom="true"
            />          
      </div>
      <div class="crop-btn">
        <van-button type="primary" size="small" plain @click="onClose">取消</van-button>
        <van-button type="success" size="small" plain @click="onCrop">剪裁</van-button>    
      </div>
    </div>

  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

import { ref , nextTick} from 'vue'

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import Footer from '@/components/Footer'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { Toast } from 'vant'
import { getUserInfo , updateCard } from '@/api'

const URL = window.URL || window.webkitURL;


export default {
    name: 'EditForm',
    components: {
      Footer,
      Cropper
    },
    async setup(){
        const store = useStore()
        const router = useRouter()

        const form = ref({addon:[]})

        const fileList = ref([]);

        const myCrop = ref(null)

        const active = ref(0)

        const showChangeIcon = ref(false)

        const crop = ref({
          show: false,
          img: null,
        })

        const validatorUrl = (val) => {
          if(val.length>0){
            return /(https?:\/\/|line:\/\/|tel:|mailto:)\S+/.test(val)
          }else{
            return true
          }
       };

      const validatorTel = (val) => {
          if(val.length>0){
            return /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}/.test(val)
          }else{
            return true
          }
       };


        // onMounted(async ()=>{
          let userRes = await getUserInfo()
          if(userRes.code===200){
            form.value = userRes.data
          }else{

          }
        // })
        if (userRes.data.nfc_addon && (userRes.data.nfc_addon.length > 0)) {
            form.value.addon = JSON.parse(userRes.data.nfc_addon)
        }

        const onCrop = () => {
          const { canvas } = myCrop.value.getResult();
          if (canvas) {
            const imgFile = new FormData();
            canvas.toBlob(async (blob) => {
              let ufile  = new File([blob], "image.jpg");
              imgFile.append("user_id", form.value.user_id)
              imgFile.append("fileType", "IMAGE")
              imgFile.append('file', ufile)

              crop.value.show = false

              Toast.loading({
                duration: 0,
                message: '圖片上傳中...',
                forbidClick: true,
              });

              let res = await axios.post(
                `${process.env.VUE_APP_API_URL}/user/uploadAvatar`,
                imgFile,
                {
                }
              )

              if (res.data.code == 200) {
                  form.value.avatar =  res.data.data
                  Toast.success('上傳成功');
              }else{
                  Toast.fail('上傳失敗');                
              }

            }, 'image/jpeg');
          }          
            
          return
		    }

        const onClose = () =>{
          crop.value.show = false
        }

        const afterRead = async(file, name) => {
          crop.value.show = true
          
          const ofile = file.file
          crop.value.img = URL.createObjectURL(ofile);
          // crop.value.img = ofile
          return
        };

        const onAddBtn = () => {
          if(form.value.addon){
            form.value.addon.push({icon:'',name:'',link:''})
          }else{
            form.value.addon = [{icon:'',name:'',link:''}]
          }
        }

        const onDelBtn = (index) => {
            form.value.addon.splice(index, 1)
        }

        const onMoveBtn = (type, index) => {
            if (type === 0) {
                if (index !== 0) {
                    [form.value.addon[index], form.value.addon[index - 1]] = [form.value.addon[index - 1], form.value.addon[index]]
                }
            } else {
                if (index + 1 !== form.value.addon.length) {
                    [form.value.addon[index + 1], form.value.addon[index]] = [form.value.addon[index], form.value.addon[index + 1]]
                }
            }

        }

        const onSubmit = async () => {
            Toast.loading({
              duration: 0,
              message: '資料更新中...',
              forbidClick: true,
            });

            let res = await updateCard(form.value)
            if(res.code===200){
                Toast.success('更新成功')
                store.commit('user/setUserInfo',form.value)
                router.push('/')
            }else{
                Toast.fail('更新失敗')
            }
        };

        return {
            form,
            fileList,
            crop,
            myCrop,
            active,
            showChangeIcon,
            onAddBtn,
            onDelBtn,
            onMoveBtn,
            validatorUrl,
            validatorTel,
            onCrop,
            onClose,            
            afterRead,
            onSubmit
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    padding-bottom: 30px;
}
.title{
    font-size: 20px;
    text-align: center;
}

.upload-img{
  width: 80px;
}

.cropper {
	height: 300px;
	// width: 300px;
	background: #DDD;
}

.cropper-section{
  margin: 0 auto;
  position: fixed;
  text-align: center;
  top: 50px;
  // left: 0;
	height: 350px;
	width: 100%;
  max-width: 500px;
  background: #DDD;
  z-index: 8888;
  .crop-area{
    margin: 5 auto;
    width: 100%;
    height: 330px;
  }
  .crop-btn{
    background-color: #666;
    text-align: center;
  }

}

.act-btn{
  text-align: center;
}

.add-btn{
  margin: 0 16px;
  padding: 10px 16px;
}

.field{
  display: flex;
  // margin: 0 16px;
  padding: 10px 16px;
  .label{
    width: var(--van-field-label-width);
    margin-right: var(--van-field-label-margin-right);
    color: var(--van-field-label-color);
  }
}

.icon-list{
  display: flex;
  width: 280px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .icon-item{
    // flex:1;
    width: 70px;
    text-align: center;
  }
}
</style>